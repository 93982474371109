import { CustomIconProps } from "../../assets/icons/add-01-solid-standard";

interface SecondaryCTAButtonsProps {
  label: string;
  onClick: () => void;
  ButtonIcon: React.FC<CustomIconProps>;
  className?: string;
  displayIcon?: boolean;
}

const SecondaryCTAButtons: React.FC<SecondaryCTAButtonsProps> = ({
  label,
  onClick,
  ButtonIcon,
  className = "",
  displayIcon = false,
}) => {
  return (
    <div
      className={`w-[8.5em] flex justify-center items-center px-5 py-3 bg-[#386163] md:bg-[#386163] rounded-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#193a3c] hover:shadow-lg mb-4 mr-4 ${className}`}
      onClick={onClick}
    >
      {/* Render the dynamic ButtonIcon */}
      {displayIcon ? (
        <ButtonIcon
          className="text-white mr-2 transition-transform duration-300 ease-in-out hover:rotate-12"
          size={24}
          color={"#FFFFFF"}
        />
      ) : (
        <></>
      )}
      <p className="font-semibold text-lg  text-white transition-colors duration-300 ease-in-out">
        {label}
      </p>
    </div>
  );
};

export default SecondaryCTAButtons;
