"use client";
import React, { useState } from "react";
import {
  motion,
  useTransform,
  AnimatePresence,
  useMotionValue,
  useSpring,
} from "framer-motion";
import { Link } from "react-router-dom";
interface CustomAnimatedTooltipProps {
  items: {
    id: number;
    name: string;
    role: string;
    link: string;
    imgSrc: string;
    onClick: () => void;
  }[];
}
export const CustomAnimatedTooltip: React.FC<CustomAnimatedTooltipProps> = ({
  items,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const springConfig = { stiffness: 100, damping: 5 };
  const x = useMotionValue(0); // going to set this value on mouse move
  // rotate the tooltip
  const rotate = useSpring(
    useTransform(x, [-100, 100], [-45, 45]),
    springConfig
  );
  // translate the tooltip
  const translateX = useSpring(
    useTransform(x, [-100, 100], [-50, 50]),
    springConfig
  );
  const handleMouseMove = (event: any) => {
    const halfWidth = event.target.offsetWidth / 2;
    x.set(event.nativeEvent.offsetX - halfWidth); // set the x value, which is then used in transform and rotate
  };

  return (
    <>
      {items.map((item, idx) => (
        <div
          className="-mr-4  relative group"
          key={item.name}
          onMouseEnter={() => setHoveredIndex(item.id)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <AnimatePresence mode="popLayout">
            {hoveredIndex === item.id && (
              <motion.div
                initial={{ opacity: 0, y: 20, scale: 0.6 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  transition: {
                    type: "spring",
                    stiffness: 260,
                    damping: 10,
                  },
                }}
                exit={{ opacity: 0, y: 20, scale: 0.6 }}
                style={{
                  translateX: translateX,
                  rotate: rotate,
                  whiteSpace: "nowrap",
                }}
                className={`absolute -top-16 -left-1/2 translate-x-1/2 flex text-xs  flex-col items-center justify-center rounded-2xl bg-[#10393b] ${
                  item.link === "/sell" ? "bg-[#ee5529]" : "bg-[#10393b]"
                } z-50 shadow-xl px-4 py-3`}
              >
                <div className="absolute inset-x-10 z-30 w-[20%] -bottom-px bg-gradient-to-r from-transparent via-emerald-500 to-transparent h-px " />
                <div className="absolute left-10 w-[40%] z-30 -bottom-px bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px " />
                <div className="font-bold text-white relative z-30 text-base">
                  {item.name}
                </div>
                <div className="text-white text-xs">{item.role}</div>
              </motion.div>
            )}
          </AnimatePresence>

          <Link to={item.link} onClick={() => item.onClick()}>
            <div
              className={`flex flex-col items-center floating-button-icon p-2 ${
                item.link === "/sell" ? "bg-[#ee5529]" : "bg-[#10393b]"
              } mr-8 transition-all duration-500 ease-in-out shadow-custom-dark`}
              onMouseMove={handleMouseMove}
            >
              <img className="h-9" src={item.imgSrc} alt="" />
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};
