import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import AlertDialog from "./AlertDialog";
import { AlertDialogConfigs } from "../../classes/AlertConfig";

function getGreeting(): string {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Goedemorgen";
    // return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Goedemiddag";
    // return "Good Afternoon";
  } else if (currentHour >= 18 && currentHour < 21) {
    return "Goedeavond";
    // return "Good Evening";
  } else {
    return "Welterusten";
    // return "Good Night";
  }
}
interface HeaderProps {
  onClickClose?: () => void;
}

const Header: React.FC<HeaderProps> = ({ onClickClose }) => {
  const navigate = useNavigate();
  const greetingText: string = getGreeting();
  const { currentUser, getCurrentUser, logOut } = useContext(GlobalContext);

  let [thisAlertConfig, setThisAlertConfig] = useState<AlertDialogConfigs>({
    isOpen: false,
    title: "",
    message: "",
    secondaryBtnText: "",
    primaryBtnText: "",
    primaryBtnAction: logOut,
  });

  useEffect(() => {
    if (getCurrentUser() == null) {
      navigate("/");
    }
    // console.log("Current User: ", getCurrentUser());
  }, [getCurrentUser, navigate]);

  return (
    <>
      <div className="header-container px-2">
        <div className="header">
          <div className="logo-container">
            <img className="w-7 h-7 mr-2" src="/logo192.png" alt="" />
            <p className="text-xl font-semibold text-[#ee5529]">AllHorses</p>
          </div>

          <div className="greatings-container">
            <h1 className="text-3xl ">{greetingText},</h1>
            <h2 className="text-lg">{currentUser?.userName}</h2>
          </div>
        </div>

        <div className="profile transition-all duration-200 ease-in-out">
          <img
            className="profile-avatar "
            src={currentUser.profilePicUrl}
            alt=""
          />
          <p
            className="text-base font-semibold px-2"
            onClick={() =>
              setThisAlertConfig({
                isOpen: true,
                title: "Weet u zeker dat u zich wilt afmelden?",
                message: "Eenmaal uitgelogd, moet u opnieuw inloggen.",
                secondaryBtnText: "Nee",
                primaryBtnText: "Logout",
                // title: "Are you sure to logout?",
                // message: "Once logged out, you need to login in again.",
                // secondaryBtnText: "No",
                // primaryBtnText: "Yes",
                primaryBtnAction: logOut,
              })
            }
          >
            Logout
          </p>
        </div>
      </div>

      <AlertDialog alertConfig={thisAlertConfig} />
      <NavBar
        onClickClose={() => {
          const onClose =
            onClickClose ||
            (() => console.log("onClickClose undefined in header!"));
          onClose();
        }}
      ></NavBar>
    </>
  );
};

export default Header;
