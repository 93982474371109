import { useRef, useState, useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { snackBarConfigType } from "../classes/SnackBarConfig";
import Header from "./ui/Header";
import CustomSnackbar from "./ui/CustomSnackbar";
import { CustomTextInput, InputFieldErrorMessage } from "./ui/CustomTextInput";
import { CustomTextAreaInput } from "./ui/CustomTextAreaInput";
import {
  FormControlLabel,
  Checkbox,
  Tab,
  Tabs,
  Box,
  CircularProgress,
} from "@mui/material";
import { CustomDropDownInput } from "./ui/CustomDropDownInput";
import {
  AdLocation,
  ageInMilliseconds,
  getHorseAge,
  HORSE_CATEGORIES,
  HORSE_COLORS,
  HORSE_EXAMINATIONS,
  HORSE_GENDERS,
  HORSE_LEVELS,
  HORSE_STUDBOOKS,
  HorseAd,
  isAdExpired,
  Price,
} from "../classes/HorseAd";
import LocationInput from "./ui/LocationInput";
import { GetContactedByWhatsApp } from "./ui/GetContactedByWhatsApp";
import {
  updateTheHorseAd,
  uploadHorseMedia,
  writeNewHorseAd,
} from "../services/firebase";
import { SelectSubsciptionPlan } from "./SelectSubscriptionPlan";
import { SubscriptionPlan } from "../classes/SubscriptionPlan";
import { PaymentOverview } from "./PaymentOverview";
import DeleteIcon from "../assets/icons/delete-icon";

interface PlaceNewAdProps {
  ad?: HorseAd;
  isEdit?: boolean;
  isPlanRenewal?: boolean;
  onClickClose?: () => void;
}

export const PlaceNewAd: React.FC<PlaceNewAdProps> = ({
  ad,
  isEdit,
  isPlanRenewal,
  onClickClose,
}) => {
  let [snackBarConfig, setSnackBarConfig] = useState<snackBarConfigType>({
    open: false,
    severity: "success",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const { currentUser, getCurrentUser } = useContext(GlobalContext);

  const userName: React.RefObject<HTMLInputElement> = useRef(null);
  const [isValidName, setisValidName] = useState(false);
  const handleNameInputField = () => {
    if (
      userName.current?.value !== undefined &&
      userName.current?.value !== null &&
      userName.current?.value.length > 3 &&
      userName.current?.value.length <= 30
    ) {
      setisValidName(true);
      // return true;
    } else {
      console.log("userName: ", userName.current?.value);
      setisValidName(false);
      // return false;
    }
  };

  const description: React.RefObject<HTMLTextAreaElement> = useRef(null);
  const [isValidDescription, setisValidDescription] = useState(false);
  const handleDescriptionInputField = () => {
    if (
      description.current?.value !== undefined &&
      description.current?.value !== null &&
      description.current?.value.length > 3 &&
      description.current?.value.length <= 500
    ) {
      setisValidDescription(true);
      // return true;
    } else {
      console.log("description: ", description.current?.value);
      setisValidDescription(false);
      // return false;
    }
  };

  const [isPricePerRequest, setPricePerRequest] = useState(false);
  const [isValidPrice, setIsValidPrice] = useState(false);

  const priceRef: React.RefObject<HTMLInputElement> = useRef(null);
  const handlePriceInputField = () => {
    if (
      !isPricePerRequest &&
      priceRef.current?.value !== undefined &&
      priceRef.current?.value !== null &&
      priceRef.current?.value.length > 0
    ) {
      setIsValidPrice(true);
    } else {
      console.log("priceRef: ", priceRef.current?.value);
      setIsValidPrice(false);
    }
    console.log("isValidPrice: ", isValidPrice);
  };

  const age: React.RefObject<HTMLInputElement> = useRef(null);
  const [isValidAge, setisValidAge] = useState(false);
  const handleAgeInputField = () => {
    if (
      age.current?.value !== undefined &&
      age.current?.value !== null &&
      parseInt(age.current?.value) < 45 &&
      parseInt(age.current?.value) > 0
    ) {
      setisValidAge(true);
    } else {
      console.log("age: ", age.current?.value);
      setisValidAge(false);
    }
  };

  const [isValidHorseHeight, setisValidHorseHeight] = useState(true);
  const handleHeightInputField = () => {
    const heightValue = horseHeightRef.current?.value;

    if (!heightValue || isNaN(parseInt(heightValue))) {
      // If the value is empty, undefined, null, or not a number
      setisValidHorseHeight(true);
    } else {
      const height = parseInt(heightValue);
      setHorseHeight(height);

      if (height >= 60 && height <= 215) {
        setisValidHorseHeight(true);
      } else {
        setisValidHorseHeight(false);
      }
    }

    console.log("horseHeightRef.current?.value: ", heightValue);
    console.log("isValidHorseHeight: ", isValidHorseHeight);
  };

  const [horseColor, setSelectedColor] = useState("");
  const [horseStudbook, setSelectedStudbook] = useState("");
  const [horseGender, setSelectedGender] = useState("");
  const [horseExaminations, setSelectedExaminations] = useState("");
  const [horseCategory, setSelectedCategory] = useState("");

  const horseHeightRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [horseHeight, setHorseHeight] = useState(0);

  const [horseLevels, setSelectedLevels] = useState("");
  const [location, setLocation] = useState<AdLocation | null>(null);
  const [getContactedByWhatsApp, setGetContactedByWhatsApp] = useState(false);
  const [linkURL, setLinkURL] = useState<string>("");
  const linkURLRef: React.RefObject<HTMLInputElement> = useRef(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);

  const [editMediaFiles, setEditMediaFiles] = useState<string[]>([]);

  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);

  const [fileInputError, setFileInputError] = useState("");
  const [adSaved, setAdSaved] = useState(false);

  useEffect(() => {
    setAdSaved(false);
    setFileInputError("Please select image");
    if (isPlanRenewal) {
      setCurrentTab(1);
    } else {
      updateTheFromBasedOnAds(ad);
    }
    // clearForm();
  }, [adSaved, fileInputError, isEdit, ad, isPlanRenewal]);

  const handleSelectButtonClick = () => {
    // Trigger file input click when the select button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileListArray = Array.from(files);
      // Limit to a maximum of 5 files
      if (
        editMediaFiles.length + mediaFiles.length + fileListArray.length <=
        5
      ) {
        setMediaFiles([...mediaFiles, ...fileListArray]);
        setFileInputError("");
      } else {
        // You can handle exceeding the limit (e.g., show a message)
        console.error("Maximum of 5 files allowed.");
        setFileInputError("Maximaal 5 bestanden toegestaan");
      }
    }
  };

  const checkIfAllFieldsAreValid = (): boolean => {
    const isValidForm =
      isValidName &&
      isValidDescription &&
      isValidPrice &&
      isValidHorseHeight &&
      horseColor &&
      horseStudbook &&
      horseGender &&
      horseExaminations &&
      horseCategory &&
      horseLevels &&
      mediaFiles.length <= 5;

    console.log(
      "--------------------------------------------------------------"
    );
    console.log("isValidName:", isValidName);
    console.log("isValidDescription:", isValidDescription);
    console.log("isValidPrice:", isValidPrice);
    console.log("isValidHorseHeight:", isValidHorseHeight);
    console.log("horseColor:", horseColor);
    console.log("horseStudbook:", horseStudbook);
    console.log("horseGender:", horseGender);
    console.log("horseExaminations:", horseExaminations);
    console.log("horseCategory:", horseCategory);
    console.log("horseLevels:", horseLevels);
    console.log("mediaFiles.length:", mediaFiles.length);
    console.log("Form is valid:", isValidForm);
    console.log(
      "--------------------------------------------------------------"
    );

    if (
      isValidName &&
      isValidDescription &&
      isValidPrice &&
      isValidHorseHeight &&
      horseColor &&
      horseStudbook &&
      horseGender &&
      horseExaminations &&
      horseCategory &&
      horseLevels &&
      mediaFiles.length <= 5
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveButton = () => {
    console.log("checkIfAllFieldsAreValid: ", checkIfAllFieldsAreValid());

    if (checkIfAllFieldsAreValid()) {
      const horsePrice: Price = {
        actualAmount: isPricePerRequest
          ? 0
          : parseInt(priceRef.current?.value ?? "0"),
        currency: "EUR",
        fromTo: 0,
        to: 0,
      };

      const emptyLocation: AdLocation = {
        addressLine1: "",
        addressLine2: "",
        area_code: "",
        city: "",
        country: "",
        state: "",
        lat: 0,
        longitude: 0,
      };

      const horseAge = ageInMilliseconds(age.current?.value ?? "-1");

      const docIdInNum: number =
        isEdit && ad?.id ? parseInt(ad.id) : Date.now();

      const lastPaymentTime: number = isEdit ? ad?.lastPaymentTime ?? 0 : 0;

      const newHorseAdData: HorseAd = {
        age: horseAge,
        breedId: 0,
        category: horseCategory,
        color: horseColor,
        colorId: 0,
        contactViaWhatsApp: getContactedByWhatsApp,
        createdtime: docIdInNum,
        description: description.current?.value ?? "",
        documents: [linkURL],
        examination: horseExaminations,
        favourite: false,
        gender: horseGender,
        height: horseHeight,
        id: docIdInNum.toString(),
        image: "",
        lastPaymentTime: lastPaymentTime,
        level: horseLevels,
        location: location ?? emptyLocation,
        media: [...editMediaFiles],
        name: userName.current?.value ?? "",
        planName: isEdit ? ad?.planName ?? "" : "",
        price: horsePrice,
        selleruserId: currentUser.id,
        sexId: 0,
        status: isEdit ? ad?.status ?? 3 : 3,
        studbook: horseStudbook,
      };

      setSelectedAdId(newHorseAdData.id);

      console.log("mediaFiles: ", mediaFiles);
      console.log("New Horse Data: ", newHorseAdData);
      setIsLoading(true);

      uploadHorseMedia(currentUser.id, mediaFiles)
        .then((downloadableUrls) => {
          // Use the downloadableUrls array
          console.log("release media download urls: ", downloadableUrls);

          if (downloadableUrls) {
            setDownloadUrls(downloadableUrls);
          }

          if (mediaFiles.length === downloadableUrls?.length) {
            newHorseAdData.media.push(...(downloadableUrls || []));

            console.log("New Horse Data: ", newHorseAdData);
            if (isEdit && ad) {
              updateTheHorseAd(newHorseAdData)
                .then((res) => {
                  console.log("✅ New Horse Ad Data Saved!");
                  setSnackBarConfig({
                    open: true,
                    severity: "success",
                    message:
                      "Uw paard is opgeslagen. Selecteer een plan en betaal om het aan de wereld te laten zien!",
                  });
                  setAdSaved(true);
                  setIsLoading(false);
                  if (isEdit && hasAdExpired(ad)) {
                    setCurrentTab(1);
                  } else {
                    const onClose =
                      onClickClose ||
                      (() => console.log("onClickClose undefined!"));
                    onClose();
                  }
                })
                .catch((err) => {
                  setSnackBarConfig({
                    open: true,
                    severity: "error",
                    message: "Fout bij het opslaan van paardengegevens" + err,
                  });
                  console.log("Error while saving horse data: ", err);
                })
                .finally(() => {
                  setIsLoading(false);
                  // clearReleaseForm();
                  // setSucessfullyAddedNewRelease(true);
                });
            } else {
              writeNewHorseAd(newHorseAdData.id, newHorseAdData)
                .then((res) => {
                  console.log("✅ New Horse Ad Data Saved!");
                  setSnackBarConfig({
                    open: true,
                    severity: "success",
                    message:
                      "Uw paard is opgeslagen. Selecteer een plan en betaal om het aan de wereld te laten zien!",
                  });
                  setAdSaved(true);
                  setIsLoading(false);
                  if (true) {
                    setCurrentTab(1);
                  } else {
                    const onClose =
                      onClickClose ||
                      (() => console.log("onClickClose undefined!"));
                    onClose();
                  }
                })
                .catch((err) => {
                  setSnackBarConfig({
                    open: true,
                    severity: "error",
                    message: "Fout bij het opslaan van paardengegevens" + err,
                  });
                  console.log("Error while saving horse data: ", err);
                })
                .finally(() => {
                  setIsLoading(false);
                  // clearReleaseForm();
                  // setSucessfullyAddedNewRelease(true);
                });
            }
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
        });
    }
  };

  // Step 2: Select Subscription Plan
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [selectedAdId, setSelectedAdId] = useState<string>("");

  const handleSelectPlan = (plan: SubscriptionPlan) => {
    setSelectedPlan(plan);
    setCurrentTab(2);
  };

  //  Tab implementations
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // setCurrentTab(newValue);
  };

  const goToNextTab = () => {
    setCurrentTab((prev) => Math.min(prev + 1, 2));
  };

  const goToPreviousTab = () => {
    setCurrentTab((prev) => Math.max(prev - 1, 0));
  };

  const updateTheFromBasedOnAds = (ad: HorseAd | undefined) => {
    if (isEdit && ad) {
      console.log("Horse ad: ", ad);

      if (userName.current) {
        userName.current.value = ad.name || "";
      }

      if (description.current) {
        description.current.value = ad.description || "";
      }

      // Populate the price fields
      if (ad.price && ad.price.actualAmount === 0) {
        setPricePerRequest(true);
        setIsValidPrice(true);
      } else {
        setPricePerRequest(false);
        if (priceRef.current) {
          priceRef.current.value = ad.price
            ? ad.price.actualAmount.toString()
            : "";
        }
        handlePriceInputField();
      }

      if (age.current) {
        age.current.value = getHorseAge(ad) || "";
      }

      setSelectedColor(ad.color);
      setSelectedStudbook(ad.studbook);
      setSelectedGender(ad.gender);
      setSelectedExaminations(ad.examination);
      setSelectedLevels(ad.level);
      setSelectedCategory(ad.category);

      if (horseHeightRef.current) {
        horseHeightRef.current.value = ad.height.toString() || "";
      }

      setLocation(ad.location);
      setGetContactedByWhatsApp(ad.contactViaWhatsApp);

      if (linkURLRef.current) {
        linkURLRef.current.value = ad.documents[0];
        setLinkURL(linkURLRef.current.value);
      }

      setEditMediaFiles(ad.media);

      handleHeightInputField();
      handleAgeInputField();
      handleNameInputField();
      handleDescriptionInputField();
    }
  };

  // const clearForm = () => {
  //   if (isEdit && ad) {
  //     // console.log("Horse ad: ", ad);

  //     if (userName.current) {
  //       userName.current.value = "";
  //     }

  //     if (description.current) {
  //       description.current.value = "";
  //     }

  //     // Populate the price fields
  //     if (ad.price && ad.price.actualAmount === 0) {
  //       setPricePerRequest(true);
  //       setIsValidPrice(true);
  //     } else {
  //       setPricePerRequest(false);
  //       if (priceRef.current) {
  //         priceRef.current.value = ad.price
  //           ? ad.price.actualAmount.toString()
  //           : "";
  //       }
  //       handlePriceInputField();
  //     }

  //     if (age.current) {
  //       age.current.value = getHorseAge(ad) || "";
  //     }

  //     setSelectedColor(ad.color);
  //     setSelectedStudbook(ad.studbook);
  //     setSelectedGender(ad.gender);
  //     setSelectedExaminations(ad.examination);
  //     setSelectedLevels(ad.level);
  //     setSelectedCategory(ad.category);

  //     if (horseHeightRef.current) {
  //       horseHeightRef.current.value = ad.height.toString() || "";
  //     }

  //     setLocation(ad.location);
  //     setGetContactedByWhatsApp(ad.contactViaWhatsApp);

  //     if (linkURLRef.current) {
  //       linkURLRef.current.value = ad.documents[0];
  //       setLinkURL(linkURLRef.current.value);
  //     }

  //     setEditMediaFiles(ad.media);

  //     handleHeightInputField();
  //     handleAgeInputField();
  //     handleNameInputField();
  //     handleDescriptionInputField();
  //   }
  // };

  const handleImageDeleteOnClick = (index: number) => {
    setEditMediaFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const hasAdExpired = (ad: HorseAd | undefined): boolean => {
    const expiryTime: number = ad?.expiryTime ?? 0;

    return isAdExpired(expiryTime);
  };

  return (
    <div className="mainFrame">
      {!isEdit ? <Header /> : <></>}
      <CustomSnackbar snackbarConfig={snackBarConfig} />
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        sx={{
          "& .MuiTabs-indicator": { backgroundColor: "#FFF" },
        }}
      >
        <Tab
          label={
            !isEdit ? "1. Paardadvertentieformulier" : "1. Advertentie bewerken"
          }
          sx={{
            color: currentTab === 0 ? "#FFF" : "#ffffffa1",
            "&.Mui-selected": { color: "#FFF" },
          }}
        />

        <Tab
          label="2. Kies Abonnementsplan"
          sx={{
            color: currentTab === 0 ? "#FFF" : "#ffffffa1",
            "&.Mui-selected": { color: "#FFF" },
          }}
          disabled={!adSaved}
        />

        <Tab
          label="3. Payment Overview"
          sx={{
            color: currentTab === 0 ? "#FFF" : "#ffffffa1", // Active tab color
            "&.Mui-selected": { color: "#FFF" }, // Selected tab color
          }}
          disabled={!selectedPlan} // Disable the tab if selectedPlan is falsy
        />
      </Tabs>

      <Box hidden={currentTab !== 0}>
        {/* Horse ad form */}
        <div className={`card ${isEdit ? "w-[84vw]" : "w-[92vw]"}`}>
          {!isEdit ? (
            <div className="flex justify-center items-start py-4  md:p-6 ">
              <h2 className="text-4xl w-full text-center font-bold">
                Nieuwe Advertentie Plaatsen
              </h2>
            </div>
          ) : (
            <></>
          )}

          {isLoading ? (
            <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}

          <CustomTextInput
            isValid={isValidName}
            label="Titel"
            placeholder="titel invoeren"
            id="userName"
            name="userName"
            maxLength={150}
            inputRef={userName}
            type="text"
            handleChange={handleNameInputField}
            errorMessage="Voer een geldige titel in (min. 4 en max. 30)!"
          />

          <CustomTextAreaInput
            isValid={isValidDescription}
            label="Korte beschrijving"
            placeholder="beschrijving invoeren"
            id="description"
            name="description"
            maxLength={300}
            inputTextAreaRef={description}
            handleChange={handleDescriptionInputField}
            errorMessage="Voer een geldige omschrijving in (min. 4 en max. 500)!"
          />

          <div className="form-row my-6">
            <p className="form-element flex flex-col ml-4 mb-0">
              <label className="font-semibold text-xl mb-2 ml-3">Prijs</label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPricePerRequest}
                    onChange={(e) => {
                      const isChecked: boolean = e.target.checked;
                      setPricePerRequest(isChecked);
                      setIsValidPrice(isChecked);
                    }}
                    name="acceptTerms"
                    sx={{
                      color: "#F0663E", // Unchecked color
                      "&.Mui-checked": {
                        color: "#F0663E", // Checked color
                      },
                    }}
                    required
                  />
                }
                label="Prijs per aanvraag"
                style={{ marginRight: 0 }}
              />
              {isPricePerRequest ? (
                <></>
              ) : (
                <CustomTextInput
                  isValid={isValidPrice}
                  label=""
                  placeholder="voer de prijs in centen in"
                  id="price"
                  name="price"
                  maxLength={150}
                  inputRef={priceRef}
                  type="number"
                  handleChange={handlePriceInputField}
                  errorMessage="Prijs is vereist!"
                />
              )}
            </p>
          </div>

          <CustomTextInput
            isValid={isValidAge}
            label="Leeftijd"
            placeholder="leeftijd invoeren"
            id="age"
            name="age"
            maxLength={3}
            inputRef={age}
            type="number"
            handleChange={handleAgeInputField}
            errorMessage="Voer een geldige leeftijd in! (min. 1 en max. 44)"
          />

          <CustomDropDownInput
            isValid={horseColor ? true : false}
            label={"Kleur"}
            dropdownValues={HORSE_COLORS}
            selectedValue={horseColor}
            setValue={setSelectedColor}
            errorMessage="Kleur is vereist!"
          />

          <CustomDropDownInput
            isValid={horseStudbook ? true : false}
            label={"Stamboek"}
            dropdownValues={HORSE_STUDBOOKS}
            selectedValue={horseStudbook}
            setValue={setSelectedStudbook}
            errorMessage="Stamboek is verplicht!"
          />

          <CustomDropDownInput
            isValid={horseGender ? true : false}
            label={"Geslacht"}
            dropdownValues={HORSE_GENDERS}
            selectedValue={horseGender}
            setValue={setSelectedGender}
            errorMessage="Geslacht is vereist!"
          />

          <CustomDropDownInput
            isValid={horseExaminations ? true : false}
            label={"Keuring"}
            dropdownValues={HORSE_EXAMINATIONS}
            selectedValue={horseExaminations}
            setValue={setSelectedExaminations}
            errorMessage="Keuring is vereist!"
          />

          <CustomDropDownInput
            isValid={horseLevels ? true : false}
            label={"Niveau"}
            dropdownValues={HORSE_LEVELS}
            selectedValue={horseLevels}
            setValue={setSelectedLevels}
            errorMessage="Niveau is vereist!"
          />

          <CustomDropDownInput
            isValid={horseCategory ? true : false}
            label={"Categorie"}
            dropdownValues={HORSE_CATEGORIES}
            selectedValue={horseCategory}
            setValue={setSelectedCategory}
            errorMessage="Categorie is vereist!"
          />

          <CustomTextInput
            isValid={isValidHorseHeight}
            label="Hoogte"
            placeholder="voer de hoogte in cm in"
            id="horseHeight"
            name="horseHeight"
            inputRef={horseHeightRef}
            type="number"
            errorMessage={"Hoogte minimaal 60 cm en maximaal 215 cm opgeven"}
            handleChange={handleHeightInputField}
          />

          <LocationInput location={location} setLocation={setLocation} />

          <GetContactedByWhatsApp
            getContactedByWhatsApp={getContactedByWhatsApp}
            setGetContactedByWhatsApp={setGetContactedByWhatsApp}
            className="my-6"
          />

          <CustomTextInput
            isValid={true}
            label="Koppeling toevoegen"
            placeholder="https:// ..."
            id="linkURL"
            name="linkURL"
            inputRef={linkURLRef}
            type="text"
            handleChange={() => setLinkURL(linkURLRef.current?.value ?? "")}
          />

          <div className="form-input flex flex-col">
            <label className="font-semibold text-xl mb-3">Afbeeldingen</label>

            <input
              className="hidden"
              id=""
              multiple
              type="file"
              ref={fileInputRef}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleFileChange(event);
              }}
            />

            {/* // className="flexRow form-input-element" */}
            <div
              className={
                !(fileInputError.length === 0)
                  ? "flexRow form-input-element"
                  : "flexRow form-input-element form-field-error"
              }
              onClick={handleSelectButtonClick}
            >
              <img className="file-icon" src="/icons/file.svg" alt="" />
              <p className="form-input-label">
                Paardafbeeldingen bijvoegen (maximaal 5 afbeeldingen)
              </p>
            </div>
          </div>
          <div className="media-result">
            {/* Display selected media */}
            {mediaFiles.map((file, index) => (
              <p className="font-medium text-lg" key={index}>
                {"✨" + (index + 1) + ": " + file.name}
              </p>
            ))}

            <div className="p-2 bg-[#cad4dc] w-fit rounded-xl relative flex flex-wrap m-2">
              {editMediaFiles.map((fileUrl, index) => (
                <div
                  className="p-2 bg-[#cad4dc] w-fit rounded-xl relative m-2"
                  key={index}
                >
                  <div
                    className="absolute top-2 z-20 right-2 p-2 bg-[#ee5529] rounded-xl cursor-pointer"
                    onClick={() => handleImageDeleteOnClick(index)}
                  >
                    <DeleteIcon
                      className="text-[white]"
                      size={20}
                      color={"#ffffff"}
                    />
                  </div>
                  <img className="w-28  rounded-xl" src={fileUrl} />
                </div>
              ))}
            </div>

            <InputFieldErrorMessage
              message={fileInputError}
              isValid={!(fileInputError.length === 0)}
            />
          </div>

          <div className="flex ">
            {isEdit ? (
              <button
                id="contactFormSubmitBtn"
                className="w-full flex justify-around items-center px-3 py-3 md:px-6 md:py-4 bg-[#f9bdad] rounded-3xl cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#cf6044] hover:shadow-lg mt-10 mr-6"
                type="submit"
                onClick={onClickClose}
              >
                <p className="text-lg md:text-2xl font-bold text-[#F0663E]">
                  Annuleren
                </p>
              </button>
            ) : (
              <></>
            )}
            <button
              id="contactFormSubmitBtn"
              className="w-full flex justify-around items-center px-3 py-3 md:px-6 md:py-4 bg-[#F0663E] rounded-3xl cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#cf6044] hover:shadow-lg mt-10"
              type="submit"
              onClick={handleSaveButton}
            >
              <p className="text-lg md:text-2xl font-bold text-white">
                Opslaan en doorgaan
              </p>
            </button>
          </div>
        </div>
      </Box>

      <Box hidden={currentTab !== 1}>
        {/* Subscription Plan */}
        <div className={`card ${isEdit ? "w-[84vw]" : "w-[92vw]"}`}>
          <SelectSubsciptionPlan
            selectedHorseId={ad?.id ?? selectedAdId}
            setSelectedPlan={handleSelectPlan}
          />
        </div>
      </Box>

      <Box hidden={currentTab !== 2}>
        <div className={`card ${isEdit ? "w-[84vw]" : "w-[92vw]"}`}>
          {selectedPlan ? (
            <PaymentOverview
              selectedPlan={selectedPlan}
              selectedHorseId={ad?.id ?? selectedAdId}
              currentUserId={currentUser.id}
              backButtonClick={() => setCurrentTab(1)}
            />
          ) : (
            <p>Please select a plan ...</p>
          )}
        </div>
      </Box>
    </div>
  );
};
