interface HorseAd {
  age: number;
  breedId: number;
  category: string;
  color: string;
  colorId: number;
  contactViaWhatsApp: boolean;
  createdtime: number;
  description: string;
  documents: string[];
  examination: string;
  expiryTime?: number;
  favourite: boolean;
  gender: string;
  height: number;
  id: string;
  image: string;
  isFreshAd?: boolean;
  lastPaymentTime: number;
  level: string;
  location: AdLocation;
  media: string[];
  name: string;
  planName: string;
  price: Price;
  selleruserId: string;
  sexId: number;
  status: number;
  studbook: string;

  // aditional properties
  priceString?: string; // New
  ageString?: string; // New
  type?: string; // New
  category2?: string; // New
  characteristics?: string; // New
  sellerNameString?: string; // New
  since?: string; // New
  locationString?: string; // New
  sellerPhn?: string; // New
  sellerLocation?: string; // New
  heightString?: string; // New
  breed?: string; // New
  mainDiscipline?: string; // New
  pageURL?: string; // New
}

interface AdLocation {
  addressLine1: string;
  addressLine2: string;
  area_code: string;
  city: string;
  country: string;
  state: string;
  lat: number;
  longitude: number;
}

interface Price {
  actualAmount: number;
  currency: string;
  fromTo: number;
  to: number;
}

export function getHorseAge(ad: HorseAd): string {
  const dob = new Date(ad.age); // Convert ad's age to a Date object
  const createdDate = new Date(ad.createdtime); // Convert createdtime to a Date object

  // console.log("Dob =", dob);
  // console.log("Created Date =", createdDate);

  const calculatedAge = calculateAge(ad.age, ad.createdtime); // Calculate age using dob and createdDate

  // console.log("Calculated Age =", calculatedAge);

  return calculatedAge.toString();
}

function calculateAgeOld(dob: Date, createdDate: Date): number {
  const diffMs = createdDate.getTime() - dob.getTime(); // Difference in milliseconds
  const ageDate = new Date(diffMs); // Convert the difference into a Date object
  return Math.abs(ageDate.getUTCFullYear() - 1970); // Calculate the age
}

function calculateAge(dobEpoch: number, currentEpoch: number): number {
  const dob = new Date(dobEpoch);
  const current = new Date(currentEpoch);

  const dobYear = dob.getUTCFullYear();
  const dobMonth = dob.getUTCMonth();
  const dobDay = dob.getUTCDate();

  const currentYear = current.getUTCFullYear();
  const currentMonth = current.getUTCMonth();
  const currentDay = current.getUTCDate();

  let age = currentYear - dobYear;

  // Adjust age if the current date is before the birthday this year
  if (
    currentMonth < dobMonth ||
    (currentMonth === dobMonth && currentDay < dobDay)
  ) {
    // age--;
  }

  return age;
}

export const HORSE_COLORS = [
  "Vos",
  "Bruin",
  "Donkerbruin",
  "Zwart",
  "Schimmel",
  "Palomino",
  "Bont",
  "Roan bruin",
  "Anders",
];
export const HORSE_STUDBOOKS = [
  "KWPN",
  "NRPS",
  "BWP",
  "Hannover",
  "Holstein",
  "SBS",
  "Westfalen",
  "Zangerheide",
  "Oldenberg",
  "Fries",
  "Pre",
  "Quarter horse",
  "Arabier",
  "Bont paard",
  "Deens warmbloed",
  "Groninger stamboek",
  "Trakehnen",
  "Volbloed",
  "Warmbloed",
  "Zweedse warmbloed",
  "Anders",
];
export const HORSE_GENDERS = ["Merrie", "Ruin", "Hengst"];
export const HORSE_EXAMINATIONS = [
  "Klinisch",
  "Röntgenlogisch",
  "Röntgenlogisch en Klinisch",
  "Niet van toepassing",
];
export const HORSE_LEVELS = [
  "Onbeleerd",
  "Zadelmak",
  "B",
  "L",
  "M",
  "Z",
  "ZZ",
  "Lichte tour",
  "Zware tour",
  "Internationaal",
  "1.00M",
  "1.10M",
  "1.20M",
  "1.30M",
  "1.40M",
];
export const HORSE_CATEGORIES = [
  "Dressuurpaarden",
  "Springpaarden",
  "Recreatief",
  "Allround",
  "Fokmerries",
];

export const ageInMilliseconds = (ageInNumber: string): number => {
  const currentTimeInMs = Date.now(); // Equivalent to System.currentTimeMillis() in JavaScript
  const ageInYears = parseInt(ageInNumber);
  const ageInMs = ageInYears * 365 * 24 * 60 * 60 * 1000; // Convert age in years to milliseconds

  return currentTimeInMs - ageInMs;
};

export const isAdExpired = (expiryTime: number): boolean => {
  const currentTimestamp = new Date().getTime(); // Current time in milliseconds

  if (currentTimestamp > expiryTime) {
    return true;
  }
  return false;
};

export const getExpiryLabelAndColor = (
  expiryTime: number,
  lastPaymentTime: number
): {
  label: string;
  color: string;
} => {
  let isExpired = false;
  const expDate = expiryTime;
  const oneDayInMs = 24 * 60 * 60 * 1000; // 1 day in milliseconds

  const currentTimestamp = new Date().getTime(); // Current time in milliseconds

  if (currentTimestamp > expDate) {
    // console.log("GOOD dt", expDate);
    isExpired = true;
  }

  if (expDate === 0) {
    if (lastPaymentTime === 0) {
      return { label: "Verlopen!", color: "#FF1A1A" }; // Replace with localization function
    } else {
      return { label: "Freemium", color: "#009A19" }; // Replace with localization function if needed
    }
  } else {
    if (!isExpired) {
      const daysInMs = expDate - currentTimestamp;
      const days = Math.floor(daysInMs / oneDayInMs);

      if (days === 0) {
        return { label: "Verloopt vandaag!", color: "#FB9900" }; // Replace with localization function
      } else if (days === 1) {
        return { label: `Verloopt in ${days} dag!`, color: "#FB9900" }; // Replace with localization function
      } else {
        return { label: `Verloopt in ${days} dagen!`, color: "#FB9900" }; // Replace with localization function
      }
    } else {
      return { label: "Verlopen!", color: "#FF1A1A" }; // Replace with localization function
    }
  }
};

export const getHorseAgeString = (ad: HorseAd): string => {
  if (ad.age == 0) {
    if (ad.ageString && ad.ageString !== "") {
      return ad.ageString;
    } else {
      return "0";
    }
  } else {
    return getHorseAge(ad) + " jaar";
  }
};
export const getHorseHeightString = (ad: HorseAd): string => {
  if (ad.height > 0) {
    return ad.height + " cm";
  } else if (ad.heightString && ad.heightString !== "") {
    return ad.heightString;
  } else {
    return "";
  }
};

// Country Translation Map
export const countryTranslationMap: { [key: string]: string } = {
  Germany: "Duitsland",
  Netherlands: "Nederland",
  Belgium: "België",
  France: "Frankrijk",
  Spain: "Spanje",
  Italy: "Italië",
  Austria: "Oostenrijk",
  Switzerland: "Zwitserland",
  "United Kingdom": "Verenigd Koninkrijk",
  "United States": "Verenigde Staten",
  Canada: "Canada",
  Australia: "Australië",
  "New Zealand": "Nieuw-Zeeland",
  Norway: "Noorwegen",
  Sweden: "Zweden",
  Denmark: "Denemarken",
  Finland: "Finland",
  Ireland: "Ierland",
  Portugal: "Portugal",
  Greece: "Griekenland",
  Poland: "Polen",
  "Czech Republic": "Tsjechië",
  Hungary: "Hongarije",
  Romania: "Roemenië",
  Bulgaria: "Bulgarije",
  Russia: "Rusland",
  China: "China",
  Japan: "Japan",
  India: "India",
  "South Africa": "Zuid-Afrika",
  Brazil: "Brazilië",
  Mexico: "Mexico",
  Argentina: "Argentinië",
  Chile: "Chili",
  Colombia: "Colombia",
  Peru: "Peru",
  Egypt: "Egypte",
  Morocco: "Marokko",
  Turkey: "Turkije",
  Israel: "Israël",
  "Saudi Arabia": "Saudi-Arabië",
  "United Arab Emirates": "Verenigde Arabische Emiraten",
  Singapore: "Singapore",
  Malaysia: "Maleisië",
  Indonesia: "Indonesië",
  Philippines: "Filipijnen",
  Thailand: "Thailand",
  Vietnam: "Vietnam",
  "South Korea": "Zuid-Korea",
};

const getPrice = (rawPrice: number): string => {
  if (rawPrice == 0) {
    return "Prijs op aanvraag";
  }
  // Convert the number to a string
  const priceString = rawPrice.toString();

  // Insert dots to separate thousands
  const parts = [];
  let i = priceString.length;

  // Process the string in reverse, adding dots every three digits
  while (i > 0) {
    parts.push(priceString.slice(Math.max(0, i - 3), i));
    i -= 3;
  }

  // Join parts and reverse the order
  return parts.reverse().join(".");
};

export const getPriceString = (ad: HorseAd): string => {
  const actualPrice = ad?.price?.actualAmount;

  if (actualPrice && actualPrice !== 0) {
    // Use the actual amount if it exists
    return getPrice(actualPrice);
  } else if (ad.priceString && ad.priceString.trim() !== "") {
    // Use the price string if available
    return ad.priceString.replace("€", "");
  } else {
    // Default fallback for undefined price
    return "Prijs op aanvraag";
  }
};

function extractPossibleCityAndCountryFromLocationString(
  locationString: string | null,
  getFullLocation: boolean
): string {
  const allCountries = new Set([
    ...Object.keys(countryTranslationMap),
    ...Object.values(countryTranslationMap),
  ]);

  if (!locationString || locationString.trim() === "") {
    console.log("Location string is empty or null.");
    return "";
  }

  console.log(`-----> Processing location string: ${locationString}`);

  // Tokenize the location string
  const tokens = locationString
    .split(/\s+/)
    .map((token) => token.replace(/[^\w\s]/g, "").trim());
  console.log(`Tokenized location string: ${tokens}`);

  let detectedCountry: string | undefined;
  let detectedCity: string | undefined;

  // Detect country
  for (const token of tokens) {
    const normalizedToken = token.trim();
    console.log(`Checking token for country: ${normalizedToken}`);

    if (countryTranslationMap[normalizedToken]) {
      detectedCountry = countryTranslationMap[normalizedToken];
      console.log(`Detected country (translated): ${detectedCountry}`);
      break;
    } else if (allCountries.has(normalizedToken)) {
      detectedCountry = normalizedToken;
      console.log(`Detected country: ${normalizedToken}`);
      break;
    }

    for (const country of allCountries) {
      if (normalizedToken.includes(country)) {
        detectedCountry = country;
        console.log(`Detected country in substring: ${country}`);
        break;
      }
    }

    if (detectedCountry) break;
  }

  // Detect city
  if (detectedCountry) {
    const countryIndex = tokens.findIndex(
      (token) =>
        countryTranslationMap[token.trim()] || allCountries.has(token.trim())
    );
    console.log(
      `Country found at index ${countryIndex}, searching for city before it.`
    );

    for (let i = countryIndex - 1; i >= 0; i--) {
      const potentialCity = tokens[i].trim();
      console.log(`Checking token for city: ${potentialCity}`);

      if (potentialCity[0]?.toUpperCase() === potentialCity[0]) {
        detectedCity = potentialCity;
        console.log(`Detected city: ${detectedCity}`);
        break;
      }
    }
  } else {
    console.log("No country detected in the location string.");
  }

  // Construct the short location
  if (detectedCity && detectedCountry) {
    console.log(`Final short location: ${detectedCity}, ${detectedCountry}`);
    return getFullLocation
      ? `${detectedCity}, ${detectedCountry}`
      : detectedCountry;
    // return `${detectedCity}, ${detectedCountry}`;
    // return detectedCountry;
  } else if (detectedCountry) {
    console.log(`Final short location (only country): ${detectedCountry}`);
    return detectedCountry;
  } else {
    console.log("Unable to detect city or country.");
    return "";
  }
}

export const getLocationStringOld = (
  ad: HorseAd,
  getFullLocation: boolean = false
): string => {
  if (ad?.location?.city && ad?.location?.city !== "") {
    return getFullLocation
      ? `${ad?.location?.city},${ad?.location?.state}, ${ad?.location?.country}`
      : `${ad?.location?.city}, ${ad?.location?.country}`;
  } else if (ad.locationString && ad.locationString !== "") {
    return extractPossibleCityAndCountryFromLocationString(
      ad.locationString,
      getFullLocation
    );
  } else {
    return "";
  }
};

export const getLocationString = async (
  ad: HorseAd,
  getFullLocation: boolean = false
): Promise<string> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (ad?.location?.city && ad?.location?.city !== "") {
        resolve(ad?.location?.city);
      } else if (ad.locationString && ad.locationString !== "") {
        resolve(
          extractPossibleCityAndCountryFromLocationString(
            ad.locationString,
            getFullLocation
          )
        );
      } else {
        resolve("");
      }
    }, 0); // Set timeout to let the event loop handle other tasks first
  });
};

export function extractAndSetCountriesFromAds(ads: Array<HorseAd | null>) {
  const detectedCountries = new Set<string>(); // Use a Set to avoid duplicates

  // Loop through each ad
  for (const ad of ads) {
    if (!ad) continue;

    // Check locationString for countries
    if (ad.locationString) {
      const locationString = ad.locationString.toLowerCase();
      // console.log(`Location String: ${locationString}`); // Debugging print statement

      // Check each country in the countryTranslationMap (both Dutch and English)
      for (const [english, dutch] of Object.entries(countryTranslationMap)) {
        if (
          locationString.includes(english.toLowerCase()) ||
          locationString.includes(dutch.toLowerCase())
        ) {
          detectedCountries.add(dutch); // Always insert Dutch name
          // console.log(`Found country in locationString: ${dutch}`); // Debugging print statement
          break; // Break early if a match is found (no need to check further countries)
        }
      }
    }

    // Check country field for countries
    if (ad.location?.country) {
      const country = ad.location.country.toLowerCase();
      // console.log(`Checking country: ${country}`); // Debugging print statement

      // Check each country in the countryTranslationMap (both Dutch and English)
      for (const [english, dutch] of Object.entries(countryTranslationMap)) {
        if (
          country.includes(english.toLowerCase()) ||
          country.includes(dutch.toLowerCase())
        ) {
          detectedCountries.add(dutch); // Always insert Dutch name
          // console.log(`Found country in location country: ${dutch}`); // Debugging print statement
          break; // Break early if a match is found
        }
      }
    }
  }

  // Convert detectedCountries to an array
  const detectedCountriesArray = Array.from(detectedCountries);

  // Update the filter locations
  // updateFilterTypeLocationsArray(detectedCountriesArray);
  console.log(`Detected countries in Dutch: ${detectedCountriesArray}`);
  return detectedCountriesArray;
}

export type { HorseAd, AdLocation, Price };
