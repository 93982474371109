import React, { useEffect, useRef, useState } from "react";

import MapsLocation01Icon from "../../assets/icons/maps-location-01-solid-standard";
import EuroCircleIcon from "../../assets/icons/euro-circle-solid-standard";
import {
  HorseAd,
  getExpiryLabelAndColor,
  getLocationString,
  getPriceString,
  isAdExpired,
} from "../../classes/HorseAd";
import Clock01Icon from "../../assets/icons/clock-icon";
import Invoice01Icon from "../../assets/icons/invoice-icon";
import DeleteIcon from "../../assets/icons/delete-icon";
import PencilEdit02Icon from "../../assets/icons/pencil-edit-02-solid-standard";
import { AlertDialogConfigs } from "../../classes/AlertConfig";
import { deleteTheHorseAd } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import SecondaryCTAButtons from "./SecondaryCTAButtons";
import RepeatIcon from "../../assets/icons/redo-icon";
import { getDominantColor } from "../../services/custom-utlis";
import AlertDialog from "./AlertDialog";

interface HorseAdCardProps {
  ad: HorseAd;
  isMyHorsesPage: boolean;
  isRelatedAds?: boolean;
  onClick: () => void;
  onClickEdit?: () => void;
  onClickRenewPlan?: () => void;
}

const getPrice = (rawPrice: number): string => {
  if (rawPrice === 0) {
    return "Prijs op aanvraag";
  }
  // Convert the number to a string
  const priceString = rawPrice.toString();

  // Insert dots to separate thousands
  const parts = [];
  let i = priceString.length;

  // Process the string in reverse, adding dots every three digits
  while (i > 0) {
    parts.push(priceString.slice(Math.max(0, i - 3), i));
    i -= 3;
  }

  // Join parts and reverse the order
  return parts.reverse().join(".");
};

const getShortName = (ogName: string): string => {
  if (ogName.length == 40) {
    return ogName;
  }

  return ogName.slice(0, 37) + " ...";
};

const HorseAdCard: React.FC<HorseAdCardProps> = ({
  ad,
  isMyHorsesPage,
  isRelatedAds,
  onClick,
  onClickEdit,
  onClickRenewPlan,
}) => {
  const navigate = useNavigate();

  const [adExpiryConfig, setAdExpiryConfig] = useState<{
    label: string;
    color: string;
  }>();

  let [thisAlertConfig, setThisAlertConfig] = useState<AlertDialogConfigs>({
    isOpen: false,
    title: "",
    message: "",
    secondaryBtnText: "",
    primaryBtnText: "",
    primaryBtnAction: () => deleteTheHorseAd(ad),
  });

  useEffect(() => {
    setAdExpiryConfig(getExpiryLabelAndColor(ad?.expiryTime ?? 0, ad?.lastPaymentTime ?? 0));
  }, [ad.expiryTime]);

  function handleDeleteOnClick() {
    // show alert and update the ad status == 2
    // console.log("delete button clicked!");

    setThisAlertConfig({
      isOpen: true,
      title: "Weet u zeker dat u de advertentie wilt verwijderen?",
      message:
        "Hierdoor wordt de advertentie definitief verwijderd en deze actie kan niet ongedaan worden gemaakt.",
      secondaryBtnText: "Nee",
      primaryBtnText: "verwijderen",
      primaryBtnAction: () => deleteTheHorseAd(ad),
    });
  }

  function handleEditOnClick() {
    // open placeNewAdd and prefill the details
    const functionCall = onClickEdit ?? undefinedOnClickEdit;

    functionCall();
  }

  const undefinedOnClickEdit = () => {
    console.log("onClickEdit is undefined");
  };

  const imgRef = useRef<HTMLImageElement>(null);
  const [dominantColor, setDominantColor] = useState<string | null>(null); // Start with null

  const handleImageLoad = () => {
    if (imgRef.current) {
      const color = getDominantColor(imgRef.current);
      setDominantColor(color);
    }
  };

  const cardStylesBasedOnConditions = isMyHorsesPage
    ? "h-[24em] w-[20em]"
    : isRelatedAds
    ? "w-[22em]"
    : "h-[20em] w-full md:w-[19em]";

  const [locationString, setLocationString] = useState("");

  useEffect(() => {
    const fetchLocation = async () => {
      getLocationString(ad).then((result) => {
        setLocationString(result);
      });
    };
    fetchLocation();
  }, [ad]);

  return (
    <div
      className={`${cardStylesBasedOnConditions} rounded-[1.3em] overflow-hidden bg-white relative mb-4 cursor-pointer md:mx-2  hover:shadow-xl`}
      onClick={onClick}
    >
      {/* <div
      className={`${
        (isMyHorsesPage) ? "h-[24em] w-[20em] " : "h-[20em] w-full md:w-[20em] "
      } ${isRelatedAds ? "w-[22em]" : "w-full"} rounded-[1.3em] overflow-hidden bg-white relative mb-4 cursor-pointer md:mx-2  hover:shadow-xl`}
      onClick={onClick}
    > */}

      <AlertDialog alertConfig={thisAlertConfig} />

      {isMyHorsesPage ? (
        <>
          <div
            className="absolute top-2 z-20 left-2 p-2 bg-[#ee5529] rounded-xl cursor-pointer"
            onClick={handleDeleteOnClick}
          >
            <DeleteIcon className="text-[white]" size={20} color={"#ffffff"} />
          </div>

          <div
            className="absolute top-2 z-20 right-2 p-2 bg-[#ee5529] rounded-xl cursor-pointer"
            onClick={handleEditOnClick}
          >
            <PencilEdit02Icon
              className="text-[white]"
              size={20}
              color={"#ffffff"}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="relative">
        <div className="absolute inset-0 overflow-hidden">
          {/* <img
            src={ad.media[0]}
            alt="Horse Background"
            className="blur-[2em] absolute inset-0 object-cover"
          /> */}
        </div>

        {/* <div className="relative z-10">
          <img
            src={ad.media[0]}
            alt="Horse"
            width={440}
            className="w-full h-44 object-contain"
          />
        </div> */}

        <div
          className="relative z-10"
          style={{
            backgroundColor: dominantColor || "rgba(255, 255, 255, 0.5)",
          }}
        >
          <img
            src={ad.media[0]}
            alt="Horse"
            width={440}
            className="w-full h-44 object-contain"
            ref={imgRef}
            onLoad={handleImageLoad} // Trigger the color extraction on load
            crossOrigin="anonymous" // Allows loading cross-origin images anonymously
          />
        </div>
      </div>

      <div className="px-4 py-4 flex flex-col justify-between flex-wrap items-start h-auto">
        <h2 className="h-12 md:overflow-y-hidden lg:overflow-y-auto text-[1em] font-semibold text-[#10393B] mb-2">
          {ad.name}
        </h2>

        <div className="flex items-center justify-start overflow-x-auto flex-wrap">
          {isMyHorsesPage ? (
            <>
              <div
                className="flex items-center p-1 pl-2 pr-3 mr-3 rounded-xl whitespace-nowrap mb-2"
                style={{ backgroundColor: `${adExpiryConfig?.color}4D` }}
              >
                <Clock01Icon
                  className={`text-[${adExpiryConfig?.color}] mr-2`}
                  size={18}
                  color={adExpiryConfig?.color}
                />
                <span
                  className="text-[0.8em]"
                  style={{ color: `${adExpiryConfig?.color}` }}
                >
                  {adExpiryConfig?.label}
                </span>
              </div>
              <div className="flex items-center bg-[#d6dedf] p-1 pl-2 pr-3 mr-3 rounded-xl whitespace-nowrap mb-2">
                <Invoice01Icon
                  className={`text-gray-500 mr-2`}
                  size={18}
                  color={"#10393B"}
                />
                <span className="text-[#10393B] text-[0.8em]">
                  {ad.planName ?? "Geen pakket"}
                </span>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="flex items-center bg-[#d6dedf] p-1 pl-2 pr-3 mr-3 rounded-xl whitespace-nowrap mb-2">
            <EuroCircleIcon
              className="text-gray-500 mr-2"
              size={18}
              color={"#10393B"}
            />
            <span className="text-[#10393B] text-[0.8em]">
              {getPriceString(ad)}
            </span>
          </div>

          {locationString && locationString !== "" && !isMyHorsesPage ? (
            <div className="flex items-center bg-[#d6dedf] p-1 pl-2 pr-3 rounded-xl whitespace-nowrap mb-2">
              <MapsLocation01Icon
                className="text-gray-500 mr-2"
                size={18}
                color={"#10393B"}
              />
              <span className="text-[#10393B] text-[0.8em]">
                {locationString}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        {isMyHorsesPage && isAdExpired(ad.expiryTime ?? 0) ? (
          <div className="flex justify-start items-center w-full mt-2">
            <button
              className="flex justify-center items-center py-1 bg-[#F0663E] rounded-3xl cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#cf6044] hover:shadow-lg "
              type="submit"
              onClick={onClickRenewPlan}
            >
              <RepeatIcon
                width={28}
                height={28}
                color="white"
                className="mx-2 p-1"
              />
              <p className="text-sm font-semibold text-white mr-3">
                Opslaan en doorgaan
              </p>
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default HorseAdCard;
