"use client";

import React, { useCallback, useEffect, useState } from "react";

import HorseAdCard from "./HorseAdCard";
import { HorseAd } from "../../classes/HorseAd";

interface RelatedAdsProps {
  thisHorse: HorseAd;
  ads: HorseAd[];
  adRelatedAdOnClick: (ad: HorseAd) => void;
}

const RelatedAds: React.FC<RelatedAdsProps> = ({
  thisHorse,
  ads,
  adRelatedAdOnClick,
}) => {
  const [relatedAds, setRelatedAds] = useState<HorseAd[]>([]);

  // Function to get exactly 5 related ads
  const getRelatedAds = useCallback((): HorseAd[] => {
    const filteredAds = ads.filter((otherHorse) => {
      const idCondition = otherHorse.id !== thisHorse.id;
      const breedCondition = otherHorse.breedId === thisHorse.breedId;
      const colorCondition = otherHorse.colorId === thisHorse.colorId;
      const genderCondition = otherHorse.gender === thisHorse.gender;
      const heightCondition = otherHorse.height === thisHorse.height;

      return (
        idCondition &&
        breedCondition &&
        colorCondition &&
        genderCondition &&
        heightCondition
      );
    });

    const additionalAdsNeeded = Math.max(0, 5 - filteredAds.length);
    const shuffledHorses = [...ads].sort(() => Math.random() - 0.5);

    const additionalAds = shuffledHorses
      .filter(
        (otherHorse) =>
          !filteredAds.some((relatedAd) => relatedAd.id === otherHorse.id)
      )
      .slice(0, additionalAdsNeeded);

    return [...filteredAds, ...additionalAds].slice(0, 5);
  }, [ads, thisHorse]);

  // Update related ads when ads or thisHorse changes
  useEffect(() => {
    setRelatedAds(getRelatedAds());
  }, [getRelatedAds]);

  return (
    <div className="mt-8">
      <h2 className="text-xl font-semibold text-[#FFF] ml-4 scroll-visible">
        Misschien vind je het ook leuk
      </h2>
      <div className="mt-4 w-[90vw] md:w-[92vw] flex justify-start pt-0 bg-[#E5E9E300] md:rounded-3xl overflow-x-auto pb-10 scroll-visible">
        <div className="h-full flex flex-row justify-start space-x-4 scroll-visible">
          {relatedAds.map((ad: HorseAd) => (
            <HorseAdCard
              key={ad.name + ad.id}
              ad={ad}
              onClick={() => adRelatedAdOnClick(ad)}
              isMyHorsesPage={false}
              isRelatedAds={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedAds;
