import React, { useState, useRef, useContext } from "react";
import {
  Snackbar,
  Alert,
  Switch,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { snackBarConfigType } from "../classes/AlertConfig";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import {
  signUp,
  uploadReleaseMedia,
  writeNewCustomer,
  writeWelcomeEmailTrigger,
} from "../services/firebase";
import User from "../classes/User";

function SignUp() {
  const navigate = useNavigate();

  const { logIn, logOut, currentUser, getCurrentUser } =
    useContext(GlobalContext);

  const [isPrivateUser, setIsPrivateUser] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [snackBarConfig, setSnackBarConfig] = useState(snackBarConfigType);
  const [error, setError] = useState("");

  const firstName: React.RefObject<HTMLInputElement> = useRef(null);
  const lastName: React.RefObject<HTMLInputElement> = useRef(null);

  const userName: React.RefObject<HTMLInputElement> = useRef(null);
  const phoneNumber: React.RefObject<HTMLInputElement> = useRef(null);
  const emailId: React.RefObject<HTMLInputElement> = useRef(null);
  const password: React.RefObject<HTMLInputElement> = useRef(null);
  const confirmPassword: React.RefObject<HTMLInputElement> = useRef(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [customerPlace, setCustomerPlace] = useState("");

  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectButtonClick = () => {
    // Trigger file input click when the select button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileListArray = Array.from(files);
      // Limit to a maximum of 5 files
      if (mediaFiles.length + fileListArray.length === 1) {
        setMediaFiles([...mediaFiles, ...fileListArray]);
      } else {
        // You can handle exceeding the limit (e.g., show a message)
        console.error("Maximum of 1 files allowed.");
      }
    }
  };

  const [isValidId, setIdValid] = useState(true);
  const handleIdInputField = () => {
    if (
      emailId.current?.value !== undefined &&
      emailId.current?.value !== null &&
      emailId.current?.value.length >= 3
    ) {
      setIdValid(true);
      // return true;
    } else {
      console.log("ID: ", emailId.current?.value);
      setIdValid(false);
      // return false;
    }
  };

  const [isValidName, setisValidName] = useState(true);
  const handleNameInputField = () => {
    if (
      userName.current?.value !== undefined &&
      userName.current?.value !== null &&
      userName.current?.value.length >= 3
    ) {
      setisValidName(true);
      // return true;
    } else {
      console.log("userName: ", userName.current?.value);
      setisValidName(false);
      // return false;
    }
  };

  const [isValidNumber, setisValidNumber] = useState(true);
  const handleNumberInputField = () => {
    if (
      phoneNumber.current?.value !== undefined &&
      phoneNumber.current?.value !== null &&
      phoneNumber.current?.value.length >= 3
    ) {
      setisValidNumber(true);
      // return true;
    } else {
      console.log("phoneNumber: ", phoneNumber.current?.value);
      setisValidNumber(false);
      // return false;
    }
  };

  const [isValidPassword, setisValidPassword] = useState(true);
  const handlePasswordInputField = () => {
    if (
      password.current?.value !== undefined &&
      password.current?.value !== null &&
      password.current?.value.length >= 6
    ) {
      setisValidPassword(true);
      // return true;
    } else {
      console.log("password: ", password.current?.value);
      setisValidPassword(false);
      // return false;
    }
  };

  const [isValidConfirmPassword, setisValidConfirmPassword] = useState(true);
  const handleConfirmPasswordInputField = () => {
    if (
      confirmPassword.current?.value !== undefined &&
      confirmPassword.current?.value !== null &&
      password.current?.value === confirmPassword.current?.value
    ) {
      setisValidConfirmPassword(true);
      // return true;
    } else {
      console.log("ID: ", confirmPassword.current?.value);
      setisValidConfirmPassword(false);
      // return false;
    }
  };

  const createUserAndSignUp = async () => {
    // handle sign-up logic here

    const newUserInfo: User = {
      userName: userName.current?.value ?? "",
      mobileNumber: phoneNumber.current?.value ?? "",
      userId: emailId.current?.value ?? "",
      id: "", // update at fb write doc function
      profilePicUrl: "",
      userType: isPrivateUser ? "private" : "business",
    };

    const m_userId = emailId.current?.value ?? "";
    const m_confPassword = confirmPassword.current?.value ?? "";

    if (!acceptTerms) {
      setSnackBarConfig({
        open: true,
        message: "U moet de voorwaarden en het privacybeleid accepteren",
        severity: "error",
      });
      return;
    } else {
      await signUpWithFBAndCreateUser(m_userId, m_confPassword, newUserInfo);
      console.log("signUp Data: ", newUserInfo);
    }
    // Additional form validations...
  };

  const handleSwitchChange = (event: any) => {
    setIsPrivateUser(event.target.checked);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarConfig({ open: false, severity: "success", message: "" });
  };

  const isValidForm = (): boolean => {
    return (
      isValidId &&
      isValidName &&
      isValidNumber &&
      isValidPassword &&
      isValidConfirmPassword
    );
  };

  const handleSignUp = (e: any) => {
    e.preventDefault();

    if (isValidForm()) {
      createUserAndSignUp();
    }
  };

  async function signUpWithFBAndCreateUser(
    m_id: string,
    m_password: string,
    userData: User
  ) {
    // console.log("m_id: ", m_id);
    // console.log("m_password: ", m_password);
    try {
      setError("");

      await signUp(m_id, m_password)
        .then((userCredential) => {
          console.log("new user id: ", userCredential.user.uid);
          const userUID = userCredential.user.uid;

          setIsLoading(true);

          uploadReleaseMedia(userUID, mediaFiles)
            .then((downloadableUrls) => {
              // Use the downloadableUrls array
              console.log("profile image download urls: ", downloadableUrls);

              if (downloadableUrls) setDownloadUrls(downloadableUrls);

              if (mediaFiles.length === downloadableUrls?.length) {
                userData.profilePicUrl = downloadableUrls[0];
              }

              userData.profilePicUrl = userData.profilePicUrl ?? "";
              console.log("new user: ", userData);

              writeNewCustomer(userUID, userData)
                .then(async (res) => {
                  console.log("new user created!");
                  setSnackBarConfig({
                    open: true,
                    message: "Registered successfully!",
                    severity: "success",
                  });
                  setIsLoading(false);

                  await signInWithFB(m_id, m_password, userData);
                })
                .catch((err) => {
                  setError(err);
                  setSnackBarConfig({
                    open: true,
                    severity: "error",
                    message: "Error while creating user" + err,
                  });
                  setIsLoading(false);
                  console.log("Error while creating user ", err);
                });
            })
            .catch((error) => {
              // Handle errors
              console.error("Error:", error);
            });
        })
        .catch((err) => {
          setError(err);
          setSnackBarConfig({
            open: true,
            severity: "error",
            message: "Error while signing up" + err,
          });
          setIsLoading(false);

          console.log("Error while signing up: ", err);
        });
    } catch (err) {
      const errString = `${err}`;
      setError(errString);
      setSnackBarConfig({
        open: true,
        severity: "error",
        message: "Error while signing up" + errString,
      });
      console.log(error);
      setIsLoading(false);
    }
  }

  async function signInWithFB(
    m_id: string,
    m_password: string,
    userData: User
  ) {
    try {
      setError("");

      console.log("sigining in the new user...");
      await logIn(m_id, m_password)
        .then(async (user) => {
          await writeWelcomeEmailTrigger(userData);

          setSnackBarConfig({
            open: true,
            message: "Welcome to Allhorses!",
            severity: "success",
          });
          setTimeout(() => {
            navigate("/home");
          }, 1000);
        })
        .catch((err) => {
          setError(err);
          setSnackBarConfig({
            open: true,
            severity: "error",
            message: "Error while signing in" + err,
          });
          console.log("Error while signing in: ", err);
        });
    } catch (err) {
      const errString = `${err}`;
      setError(errString);
      setSnackBarConfig({
        open: true,
        severity: "error",
        message: "Error while signing in" + errString,
      });
      console.log(error);
    }
  }

  return (
    <>
      <div className="mainFrame">
        {isLoading ? (
          <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        <h1 className="pageTitle-normal">Schrijf je in bij AllHorses!</h1>

        <div className="loginFormContainer">
          <form
            id="signUpForm"
            action=""
            onSubmit={(_e: any) => {
              _e.preventDefault();
            }}
          >
            {/* <div className="form-row mb-4">
              <p className="form-element">
                <label htmlFor="firstName"></label>
                <input
                  className="form-input-element"
                  maxLength={100}
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  id="firstName"
                  ref={firstName}
                  required
                />
              </p>
            </div>
            <div className="form-row mb-4">
              <p className="form-element">
                <label htmlFor="lastName"></label>
                <input
                  className="form-input-element"
                  maxLength={100}
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                  ref={lastName}
                  required
                />
              </p>
            </div> */}
            <div className="form-row mb-4">
              <p className="form-element-profile-pic">
                <div className="form-input-element-profile-pic-hide">
                  <input
                    className="hidden"
                    id=""
                    type="file"
                    ref={fileInputRef}
                    value={customerPlace}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleFileChange(event);
                    }}
                  />

                  <div
                    className="flexRow form-input-element-profile-pic"
                    onClick={handleSelectButtonClick}
                  >
                    <img
                      className="w-14 mr-3"
                      src="/icons/profile-picture.svg"
                      alt=""
                    />
                    <p className="form-input-label">Selecteer Profielfoto</p>
                  </div>
                </div>
                <div className="media-result">
                  {/* Display selected media */}
                  {mediaFiles.map((file, index) => (
                    <div key={index}>
                      {"✨" + (index + 1) + ": " + file.name}
                    </div>
                  ))}

                  {/* Display download URLs 
                  {downloadUrls.map((url, index) => (
                    <div key={index}>{url}</div>
                  ))}
                */}
                </div>
              </p>
            </div>
            <div className="form-row mb-4">
              <p className="form-element">
                <label htmlFor="userName"></label>
                <input
                  className={
                    isValidName
                      ? "form-input-element"
                      : "form-input-element form-field-error"
                  }
                  maxLength={150}
                  type="text"
                  name="userName"
                  placeholder="voer uw volledige naam in"
                  id="userName"
                  ref={userName}
                  onChange={handleNameInputField}
                  required
                />
              </p>
            </div>
            <div className="form-row mb-4">
              <p className="form-element">
                <label htmlFor="phoneNumber"></label>
                <input
                  className={
                    isValidNumber
                      ? "form-input-element"
                      : "form-input-element form-field-error"
                  }
                  maxLength={18}
                  type="number"
                  name="phoneNumber"
                  placeholder="telefoonnummer"
                  id="phoneNumber"
                  ref={phoneNumber}
                  onChange={handleNumberInputField}
                  required
                />
              </p>
            </div>
            <div className="form-row mb-4">
              <p className="form-element">
                <label htmlFor="emailId"></label>
                <input
                  className={
                    isValidId
                      ? "form-input-element"
                      : "form-input-element form-field-error"
                  }
                  maxLength={250}
                  type="email"
                  name="emailId"
                  placeholder="E-mail-ID"
                  id="emailId"
                  ref={emailId}
                  onChange={handleIdInputField}
                  required
                />
              </p>
            </div>
            <div className="form-row mb-4">
              <p className="form-element">
                <label htmlFor="password"></label>
                <input
                  className={
                    isValidPassword
                      ? "form-input-element"
                      : "form-input-element form-field-error"
                  }
                  maxLength={18}
                  type="password"
                  name="password"
                  placeholder="Wachtwoord"
                  id="password"
                  ref={password}
                  onChange={handlePasswordInputField}
                  required
                />
              </p>
            </div>
            <div className="form-row mb-4">
              <p className="form-element">
                <label htmlFor="confirmPassword"></label>
                <input
                  className={
                    isValidConfirmPassword
                      ? "form-input-element"
                      : "form-input-element form-field-error"
                  }
                  maxLength={18}
                  type="password"
                  name="confirmPassword"
                  placeholder="Bevestig wachtwoord"
                  id="confirmPassword"
                  ref={confirmPassword}
                  onChange={handleConfirmPasswordInputField}
                  required
                />
              </p>
            </div>

            <div className="form-row mb-4">
              <FormControlLabel
                control={
                  <Switch
                    checked={isPrivateUser}
                    onChange={handleSwitchChange}
                    name="isPrivateUser"
                  />
                }
                label={
                  isPrivateUser ? "Privé gebruiker" : "Zakelijke gebruiker"
                }
              />
            </div>

            <div className="form-row mb-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    name="acceptTerms"
                    required
                    sx={{
                      color: "#F0663E", // Unchecked color
                      "&.Mui-checked": {
                        color: "#F0663E", // Checked color
                      },
                    }}
                  />
                }
                label="Ik accepteer de voorwaarden en het privacybeleid"
                style={{ marginRight: 0 }}
              />
            </div>

            <button
              id="signUpFormSubmitBtn"
              className="w-full flex justify-around items-center px-3 py-3 md:px-6 md:py-4 bg-[#F0663E] rounded-3xl cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#cf6044] hover:shadow-lg mt-10"
              type="submit"
              onClick={handleSignUp}
            >
              <p className="loginButtonText text-lg sm:text-xl  md:text-2xl">
                Register
              </p>
            </button>
          </form>
        </div>

        <Snackbar
          open={snackBarConfig.open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity={snackBarConfig.severity} sx={{ width: "80vw" }}>
            {snackBarConfig.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default SignUp;
